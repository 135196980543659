import {
	// Loader,
	SaveCancelButtons,
	TableActionHeader,
} from 'components/Global';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chartOfAccountAction } from 'redux/action/chartOfAccountAction';
import { classOptionsAction } from 'redux/action/classAction';
import {
	getConfiguration,
	updateConfiguration,
} from 'redux/action/configurationAction';
import { getConfigurationFieldAction } from 'redux/action/configurationFieldAction';
import {
	customerAccountAction,
	customerOptionsAction,
} from 'redux/action/customerAction';
import { getEmployeeCostAction } from 'redux/action/employeeCostAction';
import { getEmployeeCostColumnAction } from 'redux/action/employeeCostColumnSlice';
import { deleteApiWithData, getApi, postApi } from 'redux/apis';
import { AppDispatch } from 'redux/store';
import {
	checkPermission,
	createModifiedArray,
	isValidConfigurationSettings,
	toastText,
} from 'utils/utils';
import AllocationSettings from './AllocationSettings';
import ConfigurationSettings from './ConfigurationSettings';
import DecimalSettings from './DecimalSettings';
import ConfigurationHeader from './Header';
import { SettingsChangeHandler } from './MappingBox/types';
import styles from './index.module.scss';
import { ConfigurationsProps } from './types';
import { Tabs } from 'antd';
import JournalSettings from './JournalSettings';
import { CustomConfigurationMapping } from './CustomMappingRule';

// Main component for configuration page
const Configurations: FC<ConfigurationsProps> = (props) => {
	// Inits
	const { setSelectedSidebar, closeDrawerByAnimation } = props;
	const dispatch = useDispatch<AppDispatch>();
	const configurationContainerRef = useRef<HTMLDivElement>(null);
	// const { isLoading, configurations: configurationsData } = useSelector(
	// 	(state: any) => state.companies
	// );

	const [updateConfigurationLoader, setUpdateConfigurationLoader] =
		useState(false);

	const { isLoading, data: configurationsData } = useSelector(
		(state: any) => state?.configuration
	);

	const { data: configurationsFieldData } = useSelector(
		(state: any) => state?.configurationFields
	);

	useEffect(() => {
		console.log('configurationsFieldData', configurationsFieldData);
	});

	const { optionData: qbClass, isLoading: qbClassLoading } = useSelector(
		(state: any) => state?.class
	);
	const { data: qbChartOfAccounts, isLoading: qbChartOfAccountsLoading } =
		useSelector((state: any) => state.chartOfAccounts);
	const { optionData: qbCustomers, isLoading: qbCustomersLoading } =
		useSelector((state: any) => state.customer);
	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);
	const isConnected = useSelector(
		(state: any) => state.companies.selectedCompanyDetails?.company?.isConnected
	);
	const status = useSelector(
		(state: any) => state.companies.selectedCompanyDetails?.company?.status
	);

	const [isFirstTimeSubmit, setFirstTimeSubmit] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [configurationsDataCopy, setConfigurationsDataCopy] = useState(
		configurationsData?.settings
	);
	const [qbSelectionObj, setQbSelectionObj] = useState<any>({});
	const [payrollMethod, setPayrollMethod] = useState('Percentage');
	const [selectedItemDeleteData, setSelectedDelete] = useState<any>(null);
	const [indirectExpenseRate, setIndirectExpenseRate] = useState(
		configurationsData?.indirectExpenseRate
	);

	const [payPeriodId, setPayPeriodId] = useState<string | null>(null);

	const [decimalToFixedPercentage, setDecimalToFixedPercentage] = useState(
		configurationsData?.decimalToFixedPercentage
	);
	const [decimalToFixedAmount, setDecimalToFixedAmount] = useState(
		configurationsData?.decimalToFixedAmount
	);

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const [isClassRequiredForJournal, setIsClassRequiredForJournal] = useState(
		configurationsData?.isClassRequiredForJournal
	);
	const [isCustomerRequiredForJournal, setIsCustomerRequiredForJournal] =
		useState(configurationsData?.isCustomerRequiredForJournal);

	const [tabKey, setTabKey] = useState('1');

	const showSaveButtonsKeys = ['1', '2', '3'];

	useEffect(() => {
		if (payPeriodId) {
			dispatch(
				getConfigurationFieldAction({
					payPeriodId,
				})
			);

			dispatch(
				getConfiguration({
					payPeriodId,
				})
			);
		}
	}, [payPeriodId]);

	const [importStatus, setImportStatus] = useState(
		localStorage.getItem('importStatus')
	);

	const openNotification = () => {
		toastText(
			'Employee cost imported successfully. Please review your GL accounts mapping, then click "Save & Close" to continue.',
			'info',
			10000
		);
		setImportStatus('false');
		localStorage.setItem('importStatus', 'false');
	};

	if (importStatus == 'true') {
		openNotification();
	}
	// for handle the any of the settings change
	// const settingsChangeHandler = (settingsChangeData: SettingsChangeHandler) => {
	// 	const { sectionId, dataId, fieldId, fieldName } = settingsChangeData;
	// 	const tempCopy = JSON.parse(JSON.stringify(configurationsDataCopy));
	// 	tempCopy[sectionId].fields[fieldId][fieldName] = dataId;
	// 	setConfigurationsDataCopy(tempCopy);
	// };

	// Updated for handle the any of the settings change
	const settingsChangeHandler = (settingsChangeData: SettingsChangeHandler) => {
		const { sectionId, dataId, fieldId, fieldName } = settingsChangeData;
		// Create a temporary copy of the configurations data to modify
		const tempCopy = JSON.parse(JSON.stringify(configurationsDataCopy));
		// Check if there is already a field with the same name in the section
		const sectionFields = tempCopy[sectionId]?.fields || {};
		if (typeof dataId === 'string' && dataId.trim() !== '') {
			const duplicateField = Object.values(sectionFields).some(
				(field: any) => field?.label.toLowerCase() === dataId?.toLowerCase() // Check fieldName and ensure we're not comparing the same field
			);

			if (duplicateField) {
				// If duplicate is found, you can show a warning or handle it differently
				toastText(
					'Duplicate field name detected! Please choose a different name.',
					'info',
					2000
				);
				tempCopy[sectionId].fields[fieldId][fieldName] = dataId + ' Copy';
				setConfigurationsDataCopy(tempCopy);
				return; // Exit early to prevent updating the field
			}
		}

		// If no duplicate is found, proceed with the settings change
		tempCopy[sectionId].fields[fieldId][fieldName] = dataId;
		setConfigurationsDataCopy(tempCopy);
	};

	// For generate the appropriate mapping for QBclass and QBCustomer, QBChatOfAccount
	const generateMappingObject = () => {
		const quickBookClass = qbClass?.map((singleClass: any) => {
			return { ...singleClass, isDisable: false };
		});
		const quickBookCustomers = qbCustomers?.map((singleClass: any) => {
			return { ...singleClass, isDisable: false };
		});
		const quickBookChartOfAccounts = qbChartOfAccounts?.map(
			(singleClass: any) => {
				return { ...singleClass, isDisable: false };
			}
		);

		let _classList = configurationsDataCopy
			? createModifiedArray(
					quickBookClass,
					configurationsDataCopy[0].fields['f1'].value
			  )
			: quickBookClass;
		_classList = configurationsDataCopy
			? createModifiedArray(
					_classList,
					configurationsDataCopy[0].fields['f2'].value
			  )
			: _classList;

		setQbSelectionObj({
			qbCoa: quickBookChartOfAccounts,
			qbCustomer: quickBookCustomers,
			qbClass: _classList,
		});
	};

	// For handle the payroll method change type
	const changePayrollMethodHandler = (selectedPayrollMethod: string) => {
		setPayrollMethod(selectedPayrollMethod);
	};

	// For handle the expanse rate
	const changeExpenseRateAllocationHandler = (e: any) => {
		setIndirectExpenseRate(e);
	};

	// For set the default value for the selected payroll method and indirect expense
	const handleInitialState = () => {
		setConfigurationsDataCopy(configurationsData?.settings);
		setPayrollMethod(configurationsData?.payrollMethod);
		setIndirectExpenseRate(configurationsData?.indirectExpenseRate);
		setDecimalToFixedAmount(configurationsData?.decimalToFixedAmount);
		setDecimalToFixedPercentage(configurationsData?.decimalToFixedPercentage);
		setIsClassRequiredForJournal(configurationsData?.isClassRequiredForJournal);
		setIsCustomerRequiredForJournal(
			configurationsData?.isCustomerRequiredForJournal
		);
	};

	// For save the configuration
	const saveConfigurationHandler = async () => {
		setUpdateConfigurationLoader(true);
		try {
			const response = await getApi('/configuration', {
				companyId: localStorage.getItem('companyId'),
				payPeriodId,
			});
			const configurationFields = response.data.data;

			await dispatch(
				getConfigurationFieldAction({
					payPeriodId,
				})
			);
			setFirstTimeSubmit(false);
			const { isValidSelectBox, isAddEditFalse, isBlankLabel, remainingId } =
				isValidConfigurationSettings(
					configurationsDataCopy,
					indirectExpenseRate
				);
			if (remainingId) {
				const el = document.getElementById(remainingId) as HTMLElement;
				el.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});
				// el.focus();
			}
			if (!isValidSelectBox) {
				toastText('Please select valid Quickbook details', 'error');
				return;
			}

			if (!isAddEditFalse) {
				toastText('Please complete the editing of columns first', 'error');
				return;
			}

			if (isBlankLabel) {
				toastText('Please enter the valid column name', 'error');
				return;
			}

			// FOR ADD THE FIELD  IN SQL
			if (isValidSelectBox && isAddEditFalse && !isBlankLabel) {
				// let finalChanges: any = [];

				const newObject = Object.values(configurationsDataCopy);
				const fieldsObject: any = {};

				for (const singleSection of configurationFields) {
					const fieldArray = singleSection.fields.map(
						(singleField: any) => singleField.jsonId
					);
					fieldsObject[singleSection.no] = fieldArray;
				}
				const newSqlObject: any = {};

				for (const singleSection of newObject as any) {
					const data = Object.values(singleSection.fields).filter(
						(singleField: any) => {
							if (fieldsObject?.[singleSection?.id]) {
								if (
									!fieldsObject?.[singleSection?.id]?.includes(singleField.id)
								) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
						}
					);
					const sqlSection: any = configurationFields.find(
						(sqlSingleSection: any) => sqlSingleSection.no == singleSection.id
					);
					if (sqlSection?.id && data.length > 0) {
						newSqlObject[sqlSection.id] = data;
					}
				}

				// FOR DELETE THE FIELD IN SQL
				const deleteFieldObject: any = {};
				const deleteSqlSection: any = [];
				for (const [
					index,
					singleSection,
				] of configurationFields.entries() as any) {
					if (index !== 0) {
						const fieldArray: any = [];
						for (const singleField of singleSection.fields) {
							if (singleField.jsonId.charAt(0) === 'f') {
								fieldArray.push(singleField.jsonId);
							}
						}
						deleteFieldObject[singleSection.no] = fieldArray;
					}
				}

				for (const singleSection of newObject as any) {
					if (deleteFieldObject?.[singleSection?.id]) {
						for (const field of Object.values(singleSection.fields) as any) {
							const index = deleteFieldObject[singleSection.id].indexOf(
								field.id
							);
							if (index >= 0) {
								deleteFieldObject[singleSection.id].splice(index, 1);
							}
						}
					}
				}

				for (const section of configurationFields) {
					if (deleteFieldObject[section.no]) {
						const ids = deleteFieldObject[section.no];
						if (ids.length > 0) {
							const fields = section.fields.filter((singleField: any) =>
								deleteFieldObject[section.no].includes(singleField.jsonId)
							);
							for (const singleMyField of fields as any) {
								deleteSqlSection.push(singleMyField.id);
							}
						}
					}
				}

				// for (const singleSection of configurationFields) {
				// 	const data = singleSection.fields.filter((singleField) => {});
				// }

				await Promise.all(
					deleteSqlSection.map(async (singleField: string) => {
						try {
							const response = await deleteApiWithData('/configuration', {
								fieldId: singleField,
								companyId: localStorage.getItem('companyId'),
								payPeriodId,
							});
							return response;
						} catch (err) {
							console.error('API Error:', err);
						}
					})
				);

				const finalArr: any = [];

				Object.entries(newSqlObject).forEach((singleItem: any) => {
					const data = singleItem[1].map((item: any) => {
						return {
							sectionId: singleItem[0],
							jsonId: item?.id,
							name: item?.label,
							payPeriodId,
						};
					});
					finalArr.push(...data);
				});

				// Call configuration create api for field creation
				for (const singleField of finalArr) {
					try {
						await postApi('/configuration', {
							companyId: localStorage.getItem('companyId'),
							sectionId: singleField?.sectionId,
							name: singleField?.name,
							jsonId: singleField?.jsonId,
							payPeriodId,
							priority: singleField?.priority || 0,
						});
						// return response;
					} catch (err) {
						console.error('API Error:', err);
					}
				}
				// const promises = finalArr.map(async (singleField: any) => {
				// });

				// await Promise.all(promises);

				await dispatch(
					updateConfiguration({
						settings: configurationsDataCopy,
						indirectExpenseRate: Number(indirectExpenseRate.toFixed(2)),
						payrollMethod: payrollMethod,
						decimalToFixedAmount: Number(decimalToFixedAmount),
						decimalToFixedPercentage: Number(decimalToFixedPercentage),
						isClassRequiredForJournal,
						isCustomerRequiredForJournal,
						payPeriodId,
					})
				);

				configurationContainerRef.current?.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});

				await dispatch(
					getEmployeeCostColumnAction({
						payPeriodId,
					})
				)
					.unwrap()
					.then(() => {
						//
					});

				await dispatch(
					getEmployeeCostAction({
						payPeriodId,
					})
				);

				localStorage.removeItem('settings');
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
		} finally {
			setUpdateConfigurationLoader(false);
		}
	};
	// For reset the configuration
	const cancelConfigurationHandler = () => {
		setFirstTimeSubmit(true);
		setConfigurationsDataCopy(configurationsData.settings);
		handleInitialState();
	};

	//delete fieldHandler
	const deleteFieldHandler = (sectionMappingId: string, fieldId: string) => {
		const tempCopy = JSON.parse(JSON.stringify(configurationsDataCopy));
		delete tempCopy[sectionMappingId].fields[fieldId];
		setConfigurationsDataCopy(tempCopy);
	};

	//Active Inactive field handler
	const onChangeFieldStatus = (
		sectionMappingId: string,
		fieldId: string,
		flag: boolean
	) => {
		const tempCopy = JSON.parse(JSON.stringify(configurationsDataCopy));
		tempCopy[sectionMappingId].fields[fieldId].isActive = flag;
		setConfigurationsDataCopy(tempCopy);
	};

	//Field Priority Changes
	const onChangeFieldPriority = (
		sectionMappingId: string,
		fieldId: string,
		newPriority: number
	) => {
		// const tempCopy = JSON.parse(JSON.stringify(configurationsDataCopy));
		// tempCopy[sectionMappingId].fields[fieldId].priority = priority;
		// Object.values(tempCopy[sectionMappingId].fields).forEach((field: any) => {
		// 	if (field.priority >= priority && fieldId != field.id) {
		// 		field.priority = field.priority + 1;

		// 	}
		// });
		console.log(
			'sectionMappingId',
			sectionMappingId,
			'fieldId',
			fieldId,
			'newPriority',
			newPriority
		);
		const tempCopy = JSON.parse(JSON.stringify(configurationsDataCopy));
		const fields = tempCopy[sectionMappingId].fields;

		// Get the current priority of the field
		const currentPriority = fields[fieldId].priority;

		// If the new priority is the same as the current one, do nothing
		if (newPriority === currentPriority) {
			return tempCopy;
		}

		// Update the target field's priority
		fields[fieldId].priority = newPriority;

		// Adjust priorities for other fields
		Object.values(fields).forEach((field: any) => {
			if (field.id !== fieldId) {
				if (currentPriority < newPriority) {
					// Moving the field down
					if (
						field.priority > currentPriority &&
						field.priority <= newPriority
					) {
						field.priority -= 1;
					}
				} else {
					// Moving the field up
					if (
						field.priority < currentPriority &&
						field.priority >= newPriority
					) {
						field.priority += 1;
					}
				}
			}
		});
		setConfigurationsDataCopy(tempCopy);
	};

	// For add the extra field
	const addFieldHandler = (sectionID: string) => {
		const tempCopy = JSON.parse(JSON.stringify(configurationsDataCopy));
		setFirstTimeSubmit(true);
		let largestId = 0;

		for (const key in tempCopy[sectionID].fields) {
			if (key.startsWith('f')) {
				const number = parseInt(key.slice(1));
				if (!isNaN(number) && number > largestId) {
					largestId = number;
				}
			}
		}

		const id = 'f' + Number(largestId + 1);
		// const id = String(Math.random());

		const field = {
			id: id,
			label: '',
			value: null,
			creditValue: null,
			editable: true,
			deletable: true,
			ratesLimited: false,
			isEditing: true,
			isActive: true,
			priority: largestId + 1,
		};
		tempCopy[sectionID].fields = {
			...tempCopy[sectionID].fields,
			[id]: field,
		};
		setConfigurationsDataCopy(tempCopy);
	};

	// for check is there add permission
	const isAddIntegrationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Configurations',
			permission: ['add'],
		}
	);

	// for check is there add permission
	const isEditIntegrationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Configurations',
			permission: ['edit'],
		}
	);

	// for check is there add permission
	const isDeleteIntegrationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Configurations',
			permission: ['delete'],
		}
	);

	const initialFunction = () => {
		dispatch(classOptionsAction());
		dispatch(chartOfAccountAction());
		dispatch(customerOptionsAction());
		dispatch(customerAccountAction());
		if (status === false || isConnected === false) {
			toastText('Please Connect the QuickBooks company first', 'error');
			setSelectedSidebar('Integrations');
		}
	};

	// For initial fetch the class COA and customer
	useEffect(() => {
		initialFunction();
	}, [selectedCompanyDetails]);

	//   For cancel operation
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	//   For conform operation
	const handleOk = () => {
		setIsModalOpen(false);
	};

	//   For open the model
	const showModal = (sectionMappingId: string, fieldId: string) => {
		setSelectedDelete({
			sectionMappingId: sectionMappingId,
			fieldId: fieldId,
		});
		setIsModalOpen(true);
	};

	// For generate the mapping obj
	useEffect(() => {
		generateMappingObject();
	}, [qbClass, qbChartOfAccounts, qbCustomers, configurationsDataCopy]);

	useEffect(() => {
		handleInitialState();
		console.log(
			'configurationsData',
			configurationsData,
			configurationsDataCopy
		);
	}, [configurationsData]);

	if (
		isLoading ||
		qbClassLoading ||
		qbChartOfAccountsLoading ||
		qbCustomersLoading
	) {
		//return <Loader />;
	}

	const deleteHandler = () => {
		deleteFieldHandler(
			selectedItemDeleteData.sectionMappingId,
			selectedItemDeleteData.fieldId
		);
		setIsModalOpen(false);
	};

	const toFixedPercentageHandler = (value: number) => {
		setDecimalToFixedPercentage(value);
	};

	const toFixedAmountHandler = (value: number) => {
		setDecimalToFixedAmount(value);
	};

	// JSX code
	return (
		<>
			<div className={styles.configuration}>
				<TableActionHeader title="Configurations" />
				<div
					className={styles.configuration__wrapper}
					ref={configurationContainerRef}
				>
					<div className={styles['configuration__header']}>
						<ConfigurationHeader
							onChangePayPeriodId={(id: string | null) => {
								setPayPeriodId(id);
							}}
							closeDrawerByAnimation={closeDrawerByAnimation}
						/>
					</div>
					{configurationsDataCopy ? (
						// <div className={styles['configuration__settings']}>
						<>
							<Tabs
								activeKey={tabKey}
								onChange={(key) => setTabKey(key)}
								items={[
									{
										label: 'Default Mapping',
										key: '1',
										children: (
											<div
												className={
													styles['configuration__settings--configuration']
												}
												style={{ marginTop: '20px' }}
											>
												<ConfigurationSettings
													settingsData={configurationsDataCopy}
													qbSelectionObj={qbSelectionObj}
													settingsChangeHandler={settingsChangeHandler}
													isFirstTimeSubmit={isFirstTimeSubmit}
													deleteFieldHandler={deleteFieldHandler}
													addFieldHandler={addFieldHandler}
													onChangeFieldPriority={onChangeFieldPriority}
													showModal={showModal}
													indirectExpenseRate={indirectExpenseRate}
													onChangeFieldStatus={onChangeFieldStatus}
												></ConfigurationSettings>
											</div>
										),
									},
									{
										label: 'Allocation Settings',
										key: '2',
										children: (
											<div>
												<div
													className={
														styles['configuration__settings--allocation']
													}
													style={{ marginTop: '20px' }}
												>
													<AllocationSettings
														settingsData={configurationsDataCopy}
														payrollMethod={payrollMethod}
														changePayrollMethodHandler={
															changePayrollMethodHandler
														}
														indirectExpenseRate={indirectExpenseRate}
														changeExpenseRateAllocationHandler={
															changeExpenseRateAllocationHandler
														}
														settingsChangeHandler={settingsChangeHandler}
													/>
												</div>
												<div
													className={styles['configuration__settings--decimal']}
												>
													<DecimalSettings
														decimalToFixedAmount={decimalToFixedAmount}
														decimalToFixedPercentage={decimalToFixedPercentage}
														toFixedPercentageHandler={toFixedPercentageHandler}
														toFixedAmountHandler={toFixedAmountHandler}
													/>
												</div>
											</div>
										),
									},
									{
										label: 'Time Settings',
										key: '3',
										children: (
											<div style={{ marginTop: '20px' }}>
												<JournalSettings
													isClassRequiredForJournal={isClassRequiredForJournal}
													isCustomerRequiredForJournal={
														isCustomerRequiredForJournal
													}
													handleIsClassRequiredForJournal={(value) =>
														setIsClassRequiredForJournal(value)
													}
													handleIsCustomerRequiredForJournal={(value) =>
														setIsCustomerRequiredForJournal(value)
													}
												/>
											</div>
										),
									},
									{
										label: 'Custom Mapping',
										key: '4',
										children: (
											<div style={{ marginTop: '20px' }}>
												<CustomConfigurationMapping payPeriodId={payPeriodId} />
											</div>
										),
									},
								]}
							/>
							{(isAddIntegrationPermission ||
								isDeleteIntegrationPermission ||
								isEditIntegrationPermission) &&
							showSaveButtonsKeys.includes(tabKey) ? (
								<div
									className={styles['buttons-wrapper']}
									style={{ marginTop: '20px' }}
								>
									<SaveCancelButtons
										saveTitle="Save & Close"
										cancelTitle="Reset"
										loadingForSave={updateConfigurationLoader}
										loadingForCancel={false}
										saveHandler={saveConfigurationHandler}
										cancelHandler={cancelConfigurationHandler}
									/>
								</div>
							) : null}
						</>
					) : // </div>
					null}
				</div>
			</div>
			<ConfirmDelete
				handleCancel={handleCancel}
				handleOk={handleOk}
				isModalOpen={isModalOpen}
				deleteHandler={deleteHandler}
			/>
		</>
	);
};

export default Configurations;
