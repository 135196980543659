import React, { useState } from 'react';
import { Modal, Button, Typography, Checkbox, Space } from 'antd';
import styles from './index.module.scss';

const { Text } = Typography;

interface WarningModalProps {
	visible: boolean;
	onCancel: () => void;
	onConfirm: () => void;
	isLoading: boolean;
}

const WarningModal: React.FC<WarningModalProps> = ({
	visible,
	onCancel,
	onConfirm,
	isLoading,
}) => {
	const [isChecked, setIsChecked] = useState<boolean>(false);

	return (
		<Modal
			open={visible}
			onCancel={onCancel}
			footer={null}
			width={800}
		>
			<Space
				direction="vertical"
				style={{ width: '100%', padding: '10px' }}
			>
				<Typography.Title level={4}>
					Account Cancellation Notice
				</Typography.Title>
				<Text>{"We're sorry to see you go!"}</Text>
				<Text>
					Before you cancel, please note that this action will result in the
					permanent deletion of all your data, including:
				</Text>
				<div style={{ paddingLeft: '20px' }}>
					<ul>
						<li>Account Configurations</li>
						<li>Employee Costs</li>
						<li>Cost Allocations</li>
						<li>Reports</li>
					</ul>
				</div>
				<div
					style={{
						backgroundColor: '#fff3cd',
						marginTop: '5px',
						padding: '10px',
						borderRadius: '5px',
						border: '1px solid #ffeeba',
					}}
				>
					<Text strong>
						If you need any of this information, be sure to export your data
						before canceling. You can download employee costs, cost allocations,
						and reports from each account page.
					</Text>
				</div>
				<div style={{ display: 'flex', alignItems: 'flex-start' }}>
					<Checkbox onChange={(e) => setIsChecked(e.target.checked)} />
					<Text style={{ marginLeft: '8px' }}>
						I understand that all data associated with my account will be
						permanently deleted and cannot be recovered.
					</Text>
				</div>

				<Space
					style={{
						width: '100%',
						justifyContent: 'flex-end',
						marginTop: '20px',
					}}
				>
					<Button
						onClick={onCancel}
						size="large"
						className={styles['custom-button']}
					>
						Keep My Account
					</Button>
					<Button
						type="primary"
						disabled={!isChecked}
						loading={isLoading}
						onClick={onConfirm}
						style={{
							backgroundColor: '#d9534f',
							borderColor: '#d9534f',
							color: !isChecked ? '#ececec' : 'white',
						}}
						size="large"
					>
						Cancel My Account
					</Button>
				</Space>
			</Space>
		</Modal>
	);
};

export default WarningModal;
