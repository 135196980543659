import { PlusOutlined, UpCircleOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Space,
	Switch,
	Tooltip,
	TreeSelect,
	InputNumber,
	message,
	Card,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chartOfAccountAction } from 'redux/action/chartOfAccountAction';
import { postApi } from 'redux/apis';
import { AppDispatch } from 'redux/store';
import {
	BlueAddSvg,
	CloseSvg,
	DeleteActionSvg,
	EditActionSvg,
	// TickMark,
	ToolTipInfo,
} from 'utils/svgs';
import { checkPermission, filterQBEntities, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { SingleMappingSection } from './types';
import { DetailType } from 'constants/Data';

// for mapping the our column with  QB
const MappingBox: FC<SingleMappingSection> = (props) => {
	// inits
	const {
		singleMappingSection,
		qbSelectionObj,
		settingsChangeHandler,
		isFirstTimeSubmit,
		allMappingSection,
		addFieldHandler,
		deleteFieldHandler,
		// showModal,
		indirectExpenseRate,
		onChangeFieldStatus,
		onChangeFieldPriority,
	} = props;
	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);
	const qbValues = qbSelectionObj?.[singleMappingSection.type] || [];

	const [detailTypeOptions, setDetailTypeOptions] = useState<any>([]);
	// const [detailTypeValue, setDetailTypeValue] = useState(null);
	const [form] = Form.useForm();

	const handleAccountTypeChange = (value: any) => {
		setDetailTypeOptions(DetailType[value as keyof typeof DetailType]);
		// setDetailTypeValue(null);
		form.setFieldsValue({ detailType: undefined });
	};
	// for check is there add permission
	const isAddIntegrationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Configurations',
			permission: ['add'],
		}
	);
	useEffect(() => {
		handleAccountTypeChange('Expense');
		console.log('singleMappingSection', singleMappingSection);
		// or whatever defaultValue you have
	}, []);

	// for check is there add permission
	const isEditIntegrationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Configurations',
			permission: ['edit'],
		}
	);

	// for check is there add permission
	const isDeleteIntegrationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Configurations',
			permission: ['delete'],
		}
	);

	// For filter out the selected items
	const filteredItems = filterQBEntities(
		allMappingSection,
		singleMappingSection.type,
		qbValues
	);

	function findRecursively(arr: any, key: string, value: string) {
		for (const obj of arr) {
			if (obj[key] === value) {
				return obj;
			}
			if (obj.children && Array.isArray(obj.children)) {
				const result: any = findRecursively(obj.children, key, value);
				if (result) {
					return result;
				}
			}
		}
		return null;
	}

	// For select the initial value
	const findSelectBoxValueHandler = (singleMapping: any) => {
		const foundedElement: any = findRecursively(
			qbValues,
			'Id',
			singleMapping?.value
		);

		// const foundedElement = qbValues.find(
		// 	(singleEl: any) => singleEl?.Id === singleMapping?.value
		// );
		if (singleMappingSection.type === 'qbCoa') {
			return foundedElement?.Name;
		} else if (singleMappingSection.type === 'qbCustomer') {
			return foundedElement?.title;
			// return foundedElement?.DisplayName;
		} else if (singleMappingSection.type === 'qbClass') {
			return foundedElement?.title;
		}
	};

	const dispatch = useDispatch<AppDispatch>();
	const [isNewAccountModalOpen, setIsNewAccountModalOpen] = useState(false);
	const [isAccountLoading, setIsAccountLoading] = useState(false);

	const handleModalClose = () => {
		setIsNewAccountModalOpen(false);
	};

	const handleSubmit = async (values: any) => {
		try {
			setIsAccountLoading(true);
			await postApi(`/quickbooks/chart-of-account`, {
				companyId: localStorage.getItem('companyId'),
				accountName: values.accountName,
				accountNum: values.accountNumber,
				accountType: values.accountType,
				detailType: values.detailType,
			});
			toastText('New QuickBooks account created successfully', 'success');
			await dispatch(chartOfAccountAction());
			setIsAccountLoading(false);
			handleModalClose();
		} catch (err: any) {
			if (err?.response?.data?.message) {
				toastText(err?.response?.data?.message, 'error');
			} else {
				toastText(
					'Something went wrong in creating new QuickBooks account',
					'error'
				);
			}
			setIsAccountLoading(false);
		}
	};

	// State to handle modal visibility
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [singleMappingToDelete, setSingleMappingToDelete] = useState<{
		id?: string;
	} | null>(null);
	const [singleMappingSectionToDelete, setSingleMappingSectionToDelete] =
		useState<{
			id?: string;
		} | null>(null);

	// Function to handle showing the modal
	const showModal = (mapping: any, singleMappingSection: any) => {
		setSingleMappingToDelete(mapping);
		setSingleMappingSectionToDelete(singleMappingSection);
		setIsModalVisible(true);
	};

	// Function to handle the delete action
	const handleDelete = () => {
		// Replace with your actual delete logic
		if (singleMappingSectionToDelete?.id && singleMappingToDelete?.id) {
			deleteFieldHandler(
				singleMappingSectionToDelete.id,
				singleMappingToDelete.id
			);
			message.success('Field deleted successfully!');
			setIsModalVisible(false); // Close the modal after deletion
		}
	};

	// Function to close the modal without doing anything
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	// JSX
	return (
		<div
			className={`${styles['mapping-box']} ${
				!(
					isAddIntegrationPermission ||
					isEditIntegrationPermission ||
					isDeleteIntegrationPermission
				) && `${styles['remove-last']}`
			}`}
		>
			<div className={styles['mapping-box__wrapper']}>
				<div className={styles['mapping-box__top']}>
					<div className={styles['mapping-box__top--title-wrapper']}>
						<Tooltip title={singleMappingSection.toolTip}>
							<h5
								className={`${styles['mapping-box__top--title']} ${styles['mapping-box__top--title-cap']}`}
							>
								{singleMappingSection.capMappingTitle}
							</h5>
						</Tooltip>
					</div>

					<h5
						className={`${styles['mapping-box__top--title']} ${
							styles['mapping-box__top--title-qb']
						} ${
							singleMappingSection.id == 0 &&
							styles['mapping-box__top--title-qb-tooltip']
						}`}
					>
						{singleMappingSection.qbMappingValue}{' '}
						{singleMappingSection.id == 0 && (
							<Tooltip
								title={
									'Only Classes with subclasses will get synced for e.g. Parent Class: Sub Class'
								}
							>
								<div>
									<ToolTipInfo />
								</div>
							</Tooltip>
						)}
					</h5>
				</div>
				<div className={styles['mapping-box__center']}>
					{Object.values(singleMappingSection.fields)
						.sort((a: any, b: any) => a.priority - b.priority)
						.map((singleMapping: any, index: number) => {
							const selectBoxValue = findSelectBoxValueHandler(singleMapping);
							return (
								<div
									key={index}
									className={styles['mapping-box__center--wrapper']}
								>
									<div
										className={` ${styles['mapping-box__center--single-mapping']}`}
									>
										<div
											className={
												styles[
													'mapping-box__center--single-mapping--svg-wrapper'
												]
											}
										>
											<div className={styles['field-label']}></div>
											<div className={styles['edit-delete-wrapper']}></div>
										</div>
										<div
											className={
												styles[
													'mapping-box__center--single-mapping--select-wrapper'
												]
											}
										>
											{singleMappingSection.id === '1' && index === 0 ? (
												<div
													style={{ textAlign: 'center', marginBottom: '10px' }}
												>
													<h5
														className={`${styles['mapping-box__top--title']} ${styles['mapping-box__top--title-cap']}`}
													>
														GL Account - Debit
													</h5>
												</div>
											) : null}
										</div>
										{singleMappingSection.id == '1' ||
										singleMappingSection.id == '2' ||
										singleMappingSection.id == '3' ? (
											<div
												className={
													styles[
														'mapping-box__center--single-mapping--select-wrapper'
													]
												}
											>
												{singleMappingSection.id === '1' && index === 0 ? (
													<div
														style={{
															textAlign: 'center',
															marginBottom: '10px',
														}}
													>
														<h5
															className={`${styles['mapping-box__top--title']} ${styles['mapping-box__top--title-cap']}`}
														>
															GL Account - Credit
														</h5>
													</div>
												) : null}
											</div>
										) : null}
										{singleMappingSection?.id == 2 &&
										Object.values(singleMappingSection.fields).length > 1 ? (
											<p style={{ width: '20px' }}>
												{singleMapping.priority > 1 ? (
													// {/* // <button
													// // 	onClick={() => onChangeFieldPriority(
													// // 		singleMappingSection.id,
													// // 		singleMapping.id,
													// // 		singleMapping.priority - 1
													// // 	)}
													// // 	style={{ width: '30px', height: '30px', background: 'none', outline: 'none', border: 'none', cursor: 'pointer' }}
													// // >
													// // 	<UpArrowSvg/>
													// // </button> */}
													// <UpCircleOutlined
													// 	onClick={() =>
													// 		onChangeFieldPriority(
													// 			singleMappingSection.id,
													// 			singleMapping.id,
													// 			singleMapping.priority - 1
													// 		)
													// 	}
													// 	style={{
													// 		fontSize: '20px',
													// 	}}
													// />
													<></>
												) : null}
											</p>
										) : null}
									</div>
									<div
										className={` ${styles['mapping-box__center--single-mapping']}`}
									>
										<div
											className={
												styles[
													'mapping-box__center--single-mapping--svg-wrapper'
												]
											}
										>
											<div className={styles['field-label']}>
												{!singleMapping.isEditing ? (
													<>
														<h4
															id={`${singleMappingSection.id}${singleMapping.id}`}
														>
															{singleMapping.label}
														</h4>
														{singleMappingSection.id !== '5' &&
														!(
															singleMappingSection.id == '0' &&
															singleMapping.id === 'f1'
														) &&
														!(
															singleMappingSection.id == '4' &&
															!indirectExpenseRate
														) &&
														!(
															singleMappingSection.id == '0' &&
															singleMapping.id === 'f2' &&
															!indirectExpenseRate
														) &&
														singleMapping.isActive ? (
															<span className="required-field">*</span>
														) : null}
													</>
												) : (
													<Input
														size="large"
														id={`${singleMappingSection.id}${singleMapping.id}`}
														defaultValue={singleMapping.label}
														style={{ width: '80%' }}
														onChange={(event) => {
															settingsChangeHandler({
																sectionId: singleMappingSection.id,
																fieldId: singleMapping.id,
																fieldName: 'label',
																dataId: event.target.value,
															});
														}}
														onBlur={() => {
															settingsChangeHandler({
																sectionId: singleMappingSection.id,
																fieldId: singleMapping.id,
																fieldName: 'isEditing',
																dataId: !singleMapping.isEditing,
															});
														}}
													/>
												)}
											</div>
											<div className={styles['edit-delete-wrapper']}>
												{singleMapping.deletable &&
													isDeleteIntegrationPermission && (
														<>
															<Tooltip
																title="Status"
																placement="top"
															>
																<Switch
																	checked={singleMapping.isActive}
																	onChange={(e) => {
																		onChangeFieldStatus &&
																			onChangeFieldStatus(
																				singleMappingSection.id,
																				singleMapping.id,
																				e
																			);
																	}}
																	style={{ marginRight: '10px' }} // Add margin to space out from the delete button
																/>
															</Tooltip>
														</>
													)}

												{singleMapping.editable &&
													singleMapping.label.trim() !== '' &&
													isEditIntegrationPermission && (
														<>
															<Tooltip
																title="Edit"
																placement="top"
															>
																<label
																	htmlFor={`${singleMappingSection.id}${singleMapping.id}`}
																	className="cursor-pointer"
																	onClick={() => {
																		settingsChangeHandler({
																			sectionId: singleMappingSection.id,
																			fieldId: singleMapping.id,
																			fieldName: 'isEditing',
																			dataId: !singleMapping.isEditing,
																		});
																	}}
																	style={{
																		marginRight: '10px',
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'center',
																		padding: '8px',
																		borderRadius: '50%',
																		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
																		transition: 'background-color 0.3s',
																	}}
																>
																	{singleMapping.isEditing ? (
																		// <p style={{ fontSize: '14px' }}>Save</p>
																		<></>
																	) : (
																		<EditActionSvg />
																	)}
																</label>
															</Tooltip>
														</>
													)}

												{singleMappingSection?.type === 'qbCoa' &&
													(singleMappingSection.id === '1' ||
														singleMappingSection.id === '2' ||
														singleMappingSection.id === '3') && (
														<>
															{/* Tooltip for Delete Action */}
															<Tooltip
																title="Delete"
																placement="top"
															>
																<label
																	htmlFor={`delete-${singleMapping.id}`}
																	className="cursor-pointer"
																	onClick={() =>
																		showModal(
																			singleMapping,
																			singleMappingSection
																		)
																	} // Show the modal when clicked
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'center',
																		padding: '8px',
																		borderRadius: '50%',
																		boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
																		transition: 'background-color 0.3s',
																	}}
																>
																	<DeleteActionSvg />
																</label>
															</Tooltip>

															{/* AntD Modal for delete confirmation */}
															<Modal
																title={
																	<div style={{ padding: '12px 0', margin: 0 }}>
																		Confirm Deletion
																	</div>
																} // Adjusting padding and margin of title
																open={isModalVisible}
																onOk={handleDelete} // On confirm, handle the delete
																onCancel={handleCancel} // On cancel, close the modal
																okText="Delete"
																cancelText="Cancel"
																centered
																footer={[
																	// Custom footer to align buttons to the left
																	<div
																		style={{
																			paddingTop: '10px',
																		}}
																		key="footer"
																	>
																		<Button
																			key="cancel"
																			type="primary"
																			onClick={handleCancel}
																			style={{
																				marginRight: '8px',
																				backgroundColor: 'white',
																				color: '#344735',
																				borderColor: '#344735',
																			}}
																		>
																			Cancel
																		</Button>
																		<Button
																			key="delete"
																			type="primary"
																			onClick={handleDelete}
																			style={{
																				backgroundColor: '#344735',
																				borderColor: '#344735',
																			}}
																		>
																			Delete
																		</Button>
																	</div>,
																]}
															>
																<p>
																	Are you sure you want to delete the account{' '}
																	<strong>{singleMapping?.label}</strong>?
																</p>
																<Card
																	style={{ padding: '10px', marginTop: '10px' }}
																>
																	<p style={{ color: 'red', fontWeight: 600 }}>
																		Warning: Deleting this account will
																		permanently remove all associated data from
																		selected pay period, including employee cost
																		information.
																	</p>
																</Card>
															</Modal>
														</>
													)}
											</div>
										</div>
										<div
											className={
												styles[
													'mapping-box__center--single-mapping--select-wrapper'
												]
											}
										>
											{/* {singleMappingSection.id === '1' && index === 0 ? (
												<div
													style={{ textAlign: 'center', marginBottom: '10px' }}
												>
													<h5
														className={`${styles['mapping-box__top--title']} ${styles['mapping-box__top--title-cap']}`}
													>
														GL Account - Debit
													</h5>
												</div>
											) : null} */}
											{singleMappingSection?.type !== 'qbCustomer' &&
												singleMappingSection?.type !== 'qbClass' && (
													<Select
														placeholder={singleMappingSection?.placeHolder}
														size="large"
														getPopupContainer={(trigger) => trigger.parentNode}
														value={selectBoxValue}
														showSearch
														filterOption={(input, option: any) =>
															(option?.children as string)
																.toLowerCase()
																.indexOf(input.toLowerCase()) >= 0
														}
														onChange={(dataValue) => {
															settingsChangeHandler({
																sectionId: singleMappingSection.id,
																fieldId: singleMapping.id,
																fieldName: 'value',
																dataId: dataValue,
															});
														}}
														dropdownRender={(menu) => (
															<>
																{singleMappingSection.type === 'qbCoa' ? (
																	<>
																		{menu}
																		<Divider style={{ margin: '8px 0' }} />
																		<Space style={{ padding: '0 8px 4px' }}>
																			<Button
																				type="text"
																				icon={<PlusOutlined />}
																				onClick={() => {
																					setIsNewAccountModalOpen(true);
																				}}
																			>
																				Add new QuickBooks Account
																			</Button>
																		</Space>
																	</>
																) : (
																	<>{menu}</>
																)}
															</>
														)}
													>
														{singleMappingSection.id == '4' &&
														!indirectExpenseRate &&
														singleMappingSection.type === 'qbCoa' ? (
															<Select.Option value={''}>
																Select Account
															</Select.Option>
														) : !singleMapping.isActive ? (
															<Select.Option value={''}>
																Select Account
															</Select.Option>
														) : null}
														{filteredItems.map((qbData: any, key: number) => {
															if (singleMappingSection.type === 'qbCoa') {
																return (
																	<Select.Option
																		value={qbData?.Id}
																		key={key}
																		// disabled={
																		// 	singleMappingSection.id == '1' ||
																		// 	singleMappingSection.id == '2' ||
																		// 	singleMappingSection.id == '3'
																		// 		? qbData.isDisable &&
																		// 		  qbData?.Id !==
																		// 				singleMapping.creditValue
																		// 		: qbData.isDisable
																		// }
																	>
																		{qbData?.Name}
																	</Select.Option>
																);
															} else if (
																singleMappingSection.type === 'qbCustomer'
															) {
																return (
																	<Select.Option
																		value={qbData?.Id}
																		key={key}
																		disabled={qbData.isDisable}
																	>
																		{qbData?.title}
																		{/* {qbData?.DisplayName} */}
																	</Select.Option>
																);
															} else if (
																singleMappingSection.type === 'qbClass'
															) {
																return (
																	<Select.Option
																		value={qbData?.Id}
																		key={key}
																		disabled={qbData.isDisable}
																	>
																		{qbData?.FullyQualifiedName}
																	</Select.Option>
																);
															}
														})}
													</Select>
												)}

											{(singleMappingSection?.type === 'qbCustomer' ||
												singleMappingSection?.type === 'qbClass') && (
												<TreeSelect
													showSearch
													placeholder={singleMappingSection?.placeHolder}
													size="large"
													getPopupContainer={(trigger) => trigger.parentNode}
													value={selectBoxValue}
													onChange={(dataValue) => {
														settingsChangeHandler({
															sectionId: singleMappingSection.id,
															fieldId: singleMapping.id,
															fieldName: 'value',
															dataId: dataValue,
														});
													}}
													dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
													allowClear={false}
													treeDefaultExpandAll
													treeData={filteredItems}
													filterTreeNode={(inputValue, treeNode) => {
														// Customize the filtering logic here
														return treeNode.props.title
															.toLowerCase()
															.includes(inputValue.toLowerCase());
													}}
												/>
											)}

											{!isFirstTimeSubmit &&
												!singleMapping.value &&
												singleMappingSection.id !== '5' &&
												!(
													singleMappingSection.id == '0' &&
													singleMapping.id === 'f1'
												) &&
												!(
													singleMappingSection.id == '4' && !indirectExpenseRate
												) &&
												!(
													singleMappingSection.id == '0' &&
													singleMapping.id === 'f2' &&
													!indirectExpenseRate
												) &&
												singleMapping.isActive && (
													<p className="error-message">
														{singleMappingSection?.errorMessage}
													</p>
												)}
										</div>
										{singleMappingSection.id == '1' ||
										singleMappingSection.id == '2' ||
										singleMappingSection.id == '3' ? (
											<div
												className={
													styles[
														'mapping-box__center--single-mapping--select-wrapper'
													]
												}
											>
												{/* {singleMappingSection.id === '1' && index === 0 ? (
													<div
														style={{
															textAlign: 'center',
															marginBottom: '10px',
														}}
													>
														<h5
															className={`${styles['mapping-box__top--title']} ${styles['mapping-box__top--title-cap']}`}
														>
															GL Account - Credit
														</h5>
													</div>
												) : null} */}
												<Select
													placeholder={singleMappingSection?.placeHolder}
													size="large"
													getPopupContainer={(trigger) => trigger.parentNode}
													value={singleMapping?.creditValue}
													showSearch
													filterOption={(input, option: any) =>
														(option?.children as string)
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0
													}
													onChange={(dataValue) => {
														settingsChangeHandler({
															sectionId: singleMappingSection.id,
															fieldId: singleMapping.id,
															fieldName: 'creditValue',
															dataId: dataValue,
														});
													}}
													dropdownRender={(menu) => (
														<>
															{singleMappingSection.type === 'qbCoa' ? (
																<>
																	{menu}
																	<Divider style={{ margin: '8px 0' }} />
																	<Space style={{ padding: '0 8px 4px' }}>
																		<Button
																			type="text"
																			icon={<PlusOutlined />}
																			onClick={() => {
																				setIsNewAccountModalOpen(true);
																			}}
																		>
																			Add new QuickBooks Account
																		</Button>
																	</Space>
																</>
															) : (
																<>{menu}</>
															)}
														</>
													)}
												>
													{singleMappingSection.id == '4' &&
													!indirectExpenseRate &&
													singleMappingSection.type === 'qbCoa' ? (
														<Select.Option value={''}>
															Select Account
														</Select.Option>
													) : !singleMapping.isActive ? (
														<Select.Option value={''}>
															Select Account
														</Select.Option>
													) : null}
													{filteredItems.map((qbData: any, key: number) => {
														return (
															<Select.Option
																value={qbData?.Id}
																key={key}
																// disabled={
																// 	qbData.isDisable
																// 	//  && qbData?.Id !== singleMapping.value
																// }
															>
																{qbData?.Name}
															</Select.Option>
														);
													})}
												</Select>

												{!isFirstTimeSubmit &&
													!singleMapping.creditValue &&
													singleMappingSection.id !== '5' &&
													!(
														singleMappingSection.id == '0' &&
														singleMapping.id === 'f1'
													) &&
													!(
														singleMappingSection.id == '4' &&
														!indirectExpenseRate
													) &&
													!(
														singleMappingSection.id == '0' &&
														singleMapping.id === 'f2' &&
														!indirectExpenseRate
													) &&
													singleMapping.isActive && (
														<p className="error-message">
															{singleMappingSection?.errorMessage}
														</p>
													)}
											</div>
										) : null}
										{(singleMappingSection?.id == 2 ||
											singleMappingSection?.id == 1 ||
											singleMappingSection?.id == 3) &&
										Object.values(singleMappingSection.fields).length > 1 ? (
											<p style={{ width: '20px' }}>
												{/* {JSON.stringify(singleMapping.priority)} */}
												{(
													singleMappingSection?.id == 1
														? singleMapping.priority >= 2
														: singleMapping.priority > 1
												) ? (
													// <button
													// 	onClick={() => onChangeFieldPriority(
													// 		singleMappingSection.id,
													// 		singleMapping.id,
													// 		singleMapping.priority - 1
													// 	)}
													// 	style={{ width: '30px', height: '30px', background: 'none', outline: 'none', border: 'none', cursor: 'pointer' }}
													// >
													// 	{/* <UpArrowSvg/> */}
													// 	UP
													// </button>
													<UpCircleOutlined
														onClick={() =>
															onChangeFieldPriority(
																singleMappingSection.id,
																singleMapping.id,
																singleMapping.priority > 1 &&
																	singleMapping.priority - 1
															)
														}
														style={{
															fontSize: '20px',
														}}
													/>
												) : null}
											</p>
										) : null}
									</div>
									<div>
										{!isFirstTimeSubmit &&
											singleMapping.label.trim() === '' && (
												<p className="error-message">
													Please enter the field name
												</p>
											)}
									</div>
								</div>
							);
						})}
				</div>

				{singleMappingSection.addMore &&
					isAddIntegrationPermission &&
					Object.values(singleMappingSection.fields).length < 20 && (
						<div className={styles['mapping-box__bottom']}>
							<div onClick={() => addFieldHandler(singleMappingSection.id)}>
								<BlueAddSvg />
								<p className={styles['mapping-box__bottom--add-more']}>
									Add Another Item
								</p>
							</div>
						</div>
					)}
			</div>
			{isNewAccountModalOpen && (
				<div className="qbo-account">
					<Modal
						open={isNewAccountModalOpen}
						footer={false}
						onCancel={handleModalClose}
						className="qbo-account-modal"
						closable={false}
					>
						<Row className={styles['qbo-modal__header']}>
							<div className="userDetailsTitle">
								<p>Add new QuickBooks account</p>
							</div>
							<div
								className={styles['qbo-modal__header-close']}
								onClick={handleModalClose}
							>
								<CloseSvg />
							</div>
						</Row>
						<hr />
						<Form
							name="basic"
							wrapperCol={{ span: 16 }}
							style={{ maxWidth: 600 }}
							autoComplete="off"
							form={form}
							labelCol={{ span: 8 }}
							onFinish={handleSubmit}
						>
							<div className="qbo-account-form">
								<Form.Item
									label="Account Type"
									name="accountType"
									className="qbo-account-form__field"
									// initialValue={'Expense'}
								>
									{/* <Input
										disabled
										size="large"
										className="qbo-account-form__field-input"
									/> */}
									<Select
										size="large"
										className="qbo-account-form__field-input"
										defaultValue={'Expense'}
										onChange={handleAccountTypeChange}
									>
										<Select.Option value="Expense"> Expense </Select.Option>
										<Select.Option value="Other Expense">
											{' '}
											Other Expense{' '}
										</Select.Option>
										<Select.Option value="Cost of Goods Sold">
											{' '}
											Cost of Goods Sold{' '}
										</Select.Option>
										<Select.Option value="Other Current Liability">
											{' '}
											Other Current Liability{' '}
										</Select.Option>
										<Select.Option value="Long Term Liability">
											{' '}
											Long Term Liability{' '}
										</Select.Option>
									</Select>
								</Form.Item>

								<Form.Item
									label="Account Name"
									name="accountName"
									rules={[
										{ required: true, message: 'Please enter account name!' },
									]}
								>
									<Input
										size="large"
										className="qbo-account-form__field-input"
									/>
								</Form.Item>

								<Form.Item
									label="Detail Type"
									name="detailType"
									rules={[
										{
											required: true,
											message: 'Account detail type is required!',
										},
									]}
								>
									<Select
										style={{ width: 200 }}
										size="large"
										placeholder="Account"
										showSearch
										// value={
										// 	DetailType[accountType as keyof typeof DetailType][0]
										// 		.value
										// // }
										// value={detailTypeValue}
										// onChange={setDetailTypeValue}
										filterOption={(input, option: any) =>
											(option?.children as string)
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
									>
										{detailTypeOptions.map((account: any) => (
											<Select.Option
												key={account.value}
												value={account.value}
											>
												{account.label}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									label="Account Number"
									name="accountNumber"
								>
									<InputNumber
										size="large"
										type="number"
									/>
								</Form.Item>
							</div>

							<hr />

							<Row
								justify="start"
								className={`${styles['buttons']}`}
								gutter={16}
							>
								<Col
									xs={12}
									md={7}
									lg={7}
									sm={8}
								>
									<button
										className={`${styles['save']}`}
										type="submit"
										// onClick={handleSubmit}
									>
										{isAccountLoading ? (
											<img
												src="/assets/gifs/loading-black.gif"
												height={40}
											/>
										) : (
											'Save'
										)}
									</button>
								</Col>
								<Col
									xs={12}
									md={7}
									lg={7}
									sm={8}
								>
									<button
										className={styles['cancel']}
										onClick={handleModalClose}
									>
										Cancel
									</button>
								</Col>
							</Row>
						</Form>
					</Modal>
				</div>
			)}
		</div>
	);
};

export default MappingBox;
