/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, Select, Space } from 'antd';
import styles from '../Header/index.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { useSearchParams } from 'react-router-dom';
import { getCompanyDetails } from 'redux/slice/companySlice';
import { BuySubscriptionButton } from '../BuySubscriptionButton';

export const CompanySelector = () => {
	const [organizationOptions, setOrganizationOptions] = useState<any>([]);
	const dispatch = useDispatch<AppDispatch>();

	const { data: userData } = useSelector((state: any) => state?.userProfile);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [queryParams, setQueryParams] = useSearchParams();

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state?.companies
	);

	// const [selectedOrganization, setSelectedOrganization] = useState<any>(
	//     localStorage.getItem('companyId') || ''
	// );

	const organizationChangeHandler = (e: any, data: any) => {
		localStorage.setItem('companyId', e);
		localStorage.setItem('companyName', data?.children);
		localStorage.removeItem('payPeriod');
		setQueryParams({});
		// setSelectedOrganization(e);
		dispatch(getCompanyDetails(e));
	};

	useEffect(() => {
		const companies = userData?.companies?.map((company: any) => {
			return {
				companyId: company.company?.id,
				companyName: company.company?.tenantName,
			};
		});
		setOrganizationOptions(companies);
	}, [userData]);

	// const selectRef = useRef<any>(null);
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const closeCompanySelector = () => {
		setDropdownOpen(false); // Close dropdown
	};

	// const closeCompanySelector = () => {
	// 	if (selectRef.current) {
	// 		selectRef.current.blur(); // Attempt to blur first
	// 		selectRef.current.open = false; // Force close dropdown
	// 	}
	// };

	return (
		<>
			{organizationOptions?.length > 0 && (
				<Select
					placeholder="Select Organization"
					open={isDropdownOpen}
					className={styles['header__details-right--organization']}
					onDropdownVisibleChange={(open) => setDropdownOpen(open)} // Keep state in sync
					onChange={(e, data) => organizationChangeHandler(e, data)}
					value={
						selectedCompanyDetails &&
						selectedCompanyDetails.company &&
						selectedCompanyDetails.company.id
							? selectedCompanyDetails.company.id
							: null
					}
					dropdownRender={(menu) => (
						<>
							{menu}
							<Divider style={{ margin: '8px 0' }} />
							<Space style={{ padding: '0 8px 4px' }}>
								<BuySubscriptionButton
									isForDropDown={true}
									closeDropdown={closeCompanySelector}
								/>
							</Space>
						</>
					)}
				>
					{organizationOptions
						.filter((org: any) =>
							userData?.companies?.some(
								(companyDetails: any) =>
									companyDetails.companyId === org.companyId &&
									companyDetails.company.isConnected
							)
						)
						.map((company: any, key: number) => (
							<Select.Option
								value={company?.companyId}
								key={key}
							>
								{company?.companyName}
							</Select.Option>
						))}
				</Select>
			)}
		</>
	);
};
