/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Card,
	Col,
	Image,
	Row,
	// Select
} from 'antd';
import UserProfileModal from 'components/Profile';
import { SettingsBody } from 'components/settings';
import { FORMDATA } from 'constants/Data';
import SettingsLayout from 'layouts/Settings';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { getCompanyDetails } from 'redux/slice/companySlice';
import { logoutAction } from 'redux/slice/loginSlice';
import { AppDispatch } from 'redux/store';
import { checkPermission, hasText } from 'utils/utils';
import SideDrawerWrapper from '../SideDrawerWrapper';
import UserNameBox from '../UserNameBox';
import styles from './index.module.scss';
import './index.scss';
import { CompanySelector } from '../CompanySelector';
import { Badge, Button, Popover } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import useWebSocket from '../../Global/WebSocket/useWebSocket';
import { payPeriodOptionsAction } from 'redux/action/payPeriodAction';
import axios from 'axios';
import { useRef } from 'react';

// Website header
const Header = () => {
	// Inits
	const { settingsMenuItems } = FORMDATA;
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [filteredMenuItems, setFilteredMenuItems] = useState<any>([]);

	const [searchParams] = useSearchParams();

	// const settingsItem = localStorage.getItem('settings') || 'Users';

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [queryParams, setQueryParams] = useSearchParams();

	const openDrawerFlag = searchParams.get('openDrawer');
	const activeKey = searchParams.get('activeKey');

	const drawerSelection = queryParams.get('drawerSelection');

	const [settingsItem, setSettingsItem] = useState<any>(
		localStorage.getItem('settings') || 'Users'
	);
	const [selectedSidebar, setSelectedSidebar] = useState<string>(settingsItem);
	const [drawerInfo] = useState({
		drawerTitle: 'Settings',
	});
	const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);

	// const [organizationOptions, setOrganizationOptions] = useState<any>([]);
	// const [selectedOrganization, setSelectedOrganization] = useState<any>(
	// 	localStorage.getItem('companyId') || ''
	// );

	const [isLogoutLoading, setIsLogoutLoading] = useState(false);

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	/* 	const { selectedCompanyDetails } = useSelector(
		(state: any) => state?.companies
	); */

	const { data: listOfCompanies } = useSelector(
		(state: any) => state?.companies
	);

	const { data: userData } = useSelector((state: any) => state?.userProfile);
	const [profileFullName, setProfileFullName] = useState<string>('');
	const [profileImageURL, setProfileImageURL] = useState<string>('');

	useEffect(() => {
		const settingsItem = localStorage.getItem('settings');
		if (settingsItem) {
			setDrawerAnimation(true);
			setSideDrawerOpen(true);
		}
		setSettingsItem(settingsItem);
	}, [settingsItem]);

	const fetchProfile = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_OUTSETA_BASE_URL}/profile`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem(
							'outsetaAccessToken'
						)}`,
						'Content-Type': 'application/json',
					},
				}
			);
			console.log('Profile Data:', response.data);
			if (response.data) {
				const ImageURL = response.data.ProfileImageS3Url
					? response.data.ProfileImageS3Url
					: '';
				setProfileFullName(response.data.FullName);
				setProfileImageURL(ImageURL);
			}
		} catch (error) {
			console.error('Error fetching profile:', error);
		}
	};

	useEffect(() => {
		fetchProfile();
	}, []);

	// useEffect(() => {
	// 	const companies = userData?.companies?.map((company: any) => {
	// 		return {
	// 			companyId: company.company?.id,
	// 			companyName: company.company?.tenantName,
	// 		};
	// 	});
	// 	setOrganizationOptions(companies);
	// }, [userData]);

	// For remove from the dom
	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};
	// For open the sideDrawer with animation
	const openDrawerHandler: any = () => {
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};

	useEffect(() => {
		if (openDrawerFlag === 'true') {
			if (hasText(drawerSelection)) {
				localStorage.setItem('settings', drawerSelection as string);
				setSettingsItem(drawerSelection);
			}
			openDrawerHandler();
		}
		if (activeKey) {
			localStorage.setItem('settings', activeKey);
			setSelectedSidebar(activeKey);
		}
	}, [openDrawerFlag, drawerSelection]);

	useEffect(() => {
		if (
			listOfCompanies &&
			!listOfCompanies?.length &&
			userData.isFirstCompanyAdmin
		) {
			openDrawerHandler();
		}
	}, [listOfCompanies]);

	// For perform the close animation
	const closeDrawerByAnimation = () => {
		const query: any = {};

		if (queryParams.get('payPeriodId')) {
			query['payPeriodId'] = queryParams.get('payPeriodId');
		}

		setQueryParams(query);

		setDrawerAnimation(false);
		filterSettingsMenuHandler();
		localStorage.removeItem('settings');
	};

	// for handle the change of the sidebar
	const sideBarChangeHandler = (selectedValue: any) => {
		localStorage.setItem('settings', selectedValue?.key);
		setSelectedSidebar(selectedValue?.key);
	};

	// Logout Handler

	const logoutHandler = async () => {
		setIsLogoutLoading(true);
		await dispatch(logoutAction() as any)
			.unwrap()
			.then(() => {
				setIsLogoutLoading(false);
				navigate('/login');
			})
			.catch(() => {
				setIsLogoutLoading(false);
				navigate('/');
			});
	};

	// My Profile Handler
	// const myProfileHandler = () => {
	// 	const profileUrl = `${
	// 		process.env.REACT_APP_OUTSETA_PROFILE_EMBED
	// 	}?access_token=${localStorage.getItem('outsetaAccessToken')}`;

	// 	const popupWidth = window.innerWidth * 0.7; // 80% of screen width
	// 	const popupHeight = window.innerHeight * 0.8; // 70% of screen height
	// 	const left = (window.innerWidth - popupWidth) / 2 + window.screenX;
	// 	const top = (window.innerHeight - popupHeight) / 2 + window.screenY;

	// 	window.open(
	// 		profileUrl,
	// 		'OutsetaProfile',
	// 		`width=${popupWidth},height=${popupHeight},top=${top},left=${left},resizable=yes,scrollbars=yes`
	// 	);

	// 	// window.open(
	// 	// 	`${
	// 	// 		process.env.REACT_APP_OUTSETA_PROFILE_EMBED
	// 	// 	}?access_token=${localStorage.getItem('outsetaAccessToken')}`,
	// 	// 	'_self'
	// 	// );
	// };

	const profileCancel = () => {
		setProfileModalOpen(false);
	};

	// const organizationChangeHandler = (e: any, data: any) => {
	// 	localStorage.setItem('companyId', e);
	// 	localStorage.setItem('companyName', data?.children);
	// 	localStorage.removeItem('payPeriod');
	// 	setQueryParams({});
	// 	// setSelectedOrganization(e);
	// 	dispatch(getCompanyDetails(e));
	// };

	// userData?.companies?.find(
	// 	(singleCompany: any) => singleCompany.companyId === selectedOrganization
	// );

	const filterSettingsMenuHandler = () => {
		const filteredPermissionsState = settingsMenuItems.map(
			(singleMenuItem: any) => {
				return checkPermission(selectedCompanyPermission, {
					permissionName: singleMenuItem?.key,
					permission: ['view'],
				});
			}
		);

		if (!listOfCompanies?.length) {
			filteredPermissionsState[2] = true;
		}

		const filteredMenuItems = settingsMenuItems.filter(
			(_, i) => filteredPermissionsState[i]
		);

		setFilteredMenuItems(filteredMenuItems);

		// if (!selectedSidebar) {
		setSelectedSidebar(filteredMenuItems[0]?.key);
		// }
	};

	const isAnyUserPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Users',
		permission: ['add', 'all', 'edit', 'view', 'delete'],
	});
	const isAnyRolePermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Roles',
		permission: ['add', 'all', 'edit', 'view', 'delete'],
	});

	const isAnyIntegrationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Integrations',
			permission: ['add', 'all', 'edit', 'view', 'delete'],
		}
	);

	const isAnySubscriptionPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Subscriptions',
			permission: ['add', 'all', 'edit', 'view', 'delete'],
		}
	);

	const isAnyConfigurationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Configurations',
			permission: ['add', 'all', 'edit', 'view', 'delete'],
		}
	);

	const isAnySyncLogsPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Sync Logs',
		permission: ['add', 'all', 'edit', 'view', 'delete'],
	});

	const isAnyCustomRulePermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Custom Rules',
		permission: ['add', 'all', 'edit', 'view', 'delete'],
	});

	useEffect(() => {
		filterSettingsMenuHandler();
	}, [selectedCompanyPermission, listOfCompanies]);

	// console.log('userData?.id', userData?.id);
	// if (userData?.id) {
	// 	console.error('Web Socket Failed Due To Undefined parameter');
	// }

	const { message } = useWebSocket(
		'https://preliveapi.costallocationpro.com/prod',
		//'wss://cap-api.satva.solutions/api',
		// 'ws://localhost:8080',
		userData?.id
	);

	const [notifications, setNotifications] = useState<any[]>([]);

	const [visible, setVisible] = useState(false);

	// Handle popover visibility
	const handleVisibleChange = (visible: boolean) => {
		setVisible(visible);
	};

	useEffect(() => {
		if (message.length > 0) {
			// Filter new messages not already in notifications
			const newMessages = message.filter(
				(msg: any) =>
					!notifications.some(
						(notification: any) => notification.payPeriodId === msg.payPeriodId
					)
			);

			// If there are new messages, sort them and add to notifications
			if (newMessages.length > 0) {
				const sortedMessages = newMessages.sort(
					(a: any, b: any) =>
						new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
				);

				setNotifications((prevNotifications) => [
					...prevNotifications,
					...sortedMessages,
				]);
			}
			console.log('notification', notifications);
		}
	}, [message]); // Add companyId and message as dependencies

	const handleConfigOpen = (payPeriodId: string) => {
		const _query: any = {
			openDrawer: 'true',
		};

		if (queryParams.get('payPeriodId')) {
			_query.payPeriodId = payPeriodId;
		}

		if (queryParams.get('isNewPayPeriod') === 'true') {
			_query.isNewPayPeriod = 'true';
		}

		setQueryParams(_query);
		localStorage.setItem('settings', 'Configurations');
	};

	const handleNotificationClick = async (notification: any) => {
		try {
			// Assuming notification has the necessary details for payPeriodId or related params
			await dispatch(payPeriodOptionsAction());

			const query: any = {
				payPeriodId: notification?.payPeriodId, // Use appropriate field from notification or response
			};
			setQueryParams(query); // Update query parameters based on the clicked notification
			setNotifications((prevNotifications) =>
				prevNotifications.filter(
					(PrevNotification) =>
						PrevNotification.payPeriodId !== notification.payPeriodId
				)
			);

			if (notification.type == 'ImportEmployeeCost') {
				handleConfigOpen(notification.payPeriodId);
				localStorage.setItem(
					'importStatus',
					JSON.stringify(notification?.createdNewFieldFlag)
				);
			} else {
				window.location.reload();
			}

			console.log('Notification clicked:', notification);
		} catch (error) {
			console.error('Error handling notification click:', error);
		}
	};

	const clearAllNotifications = () => {
		// Logic to clear notifications
		setNotifications([]); // Assuming `setMessage` is the state updater for `message`
		console.log('All notifications cleared.');
	};

	// Popover content
	const popoverContent = (
		<div style={{ width: 300 }}>
			{notifications?.length > 0 ? (
				<>
					{notifications.map((notification: any, index: any) => (
						<Card
							key={index}
							onClick={() => handleNotificationClick(notification)}
							hoverable
							style={{
								marginBottom: 10,
								cursor: 'pointer',
								padding: '2px 8px 0px 8px',
							}}
						>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span
									style={{
										color: notification.status === 0 ? 'red' : 'inherit',
										fontWeight: 'bold',
									}}
								>
									{notification.title}
								</span>
								<span
									style={{
										fontSize: '12px',
										marginBottom: '8px',
									}}
								>
									{notification.description}
								</span>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end', // Push content to the right
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column', // Align elements vertically
										alignItems: 'flex-end', // Align text to the right
									}}
								>
									<span
										style={{
											color: '#485949',
											fontSize: '10px',
										}}
									>
										{notification.companyName}
									</span>
									<span
										style={{
											color: '#485949',
											fontSize: '10px',
										}}
									>
										{notification.createdAt}
									</span>
								</div>
							</div>
						</Card>
					))}
					<Button
						type="link"
						style={{ width: '100%', textAlign: 'center' }}
						onClick={clearAllNotifications}
					>
						Clear All
					</Button>
				</>
			) : (
				<p style={{ textAlign: 'center', margin: 0 }}>No new notifications</p>
			)}
		</div>
	);

	//outseta logout
	const hiddenButtonRef = useRef<HTMLButtonElement | null>(null);

	// const handleLogout = () => {
	// 	if (hiddenButtonRef.current) {
	// 		hiddenButtonRef.current.click(); // Programmatically click hidden button
	// 	}
	// };

	// JSX
	return (
		<>
			<header className={styles['header']}>
				<Row
					className={styles['header__wrapper']}
					align={'middle'}
					justify={'space-between'}
				>
					<Col className={styles['header__details-left']}>
						<div className={styles['header__details-left--logo']}>
							<Image
								src="/assets/images/cap-logo.png"
								preview={false}
								alt="group"
							/>
						</div>
					</Col>
					<Col className={styles['header__details-right']}>
						<CompanySelector />

						{(isAnyConfigurationPermission ||
							(listOfCompanies?.length === 0 && userData.isFirstCompanyAdmin) ||
							isAnyIntegrationPermission ||
							isAnyRolePermission ||
							isAnySubscriptionPermission ||
							isAnyCustomRulePermission ||
							isAnyUserPermission) && (
							<div
								className={styles['header__details-right--settings']}
								onClick={() => {
									openDrawerHandler();
									if (isAnyUserPermission) {
										localStorage.setItem('settings', 'Users');
									} else if (isAnyRolePermission) {
										localStorage.setItem('settings', 'Roles');
									} else if (isAnyIntegrationPermission) {
										localStorage.setItem('settings', 'Integrations');
									} else if (isAnySubscriptionPermission) {
										localStorage.setItem('settings', 'Subscription');
									} else if (isAnyConfigurationPermission) {
										localStorage.setItem('settings', 'Configurations');
									} else if (isAnySyncLogsPermission) {
										localStorage.setItem('settings', 'Sync Logs');
									} else if (isAnyCustomRulePermission) {
										localStorage.setItem('settings', 'CustomRules');
									}
								}}
							>
								<Image
									src="/assets/images/settings.png"
									preview={false}
									alt="group"
								/>
							</div>
						)}

						<div className={styles['header__details-right--settings']}>
							<Popover
								content={popoverContent}
								title="Notifications"
								trigger="click"
								open={visible}
								onOpenChange={handleVisibleChange}
							>
								<Badge
									count={notifications.length}
									offset={[10, 0]}
								>
									<BellOutlined
										style={{ fontSize: '24px', cursor: 'pointer' }}
									/>
								</Badge>
							</Popover>
						</div>

						<div className={styles['header__details-right--user']}>
							<div className={styles['header__details-right--user-logo']}>
								<UserNameBox
									name={profileFullName}
									imageUrl={`https://cap-api.satva.solutions/api/proxy-image?url=${profileImageURL}`}
								/>
							</div>
							<div className={styles['header__details-right--user-details']}>
								<p className={styles['header__details-right--user-name']}>
									{profileFullName}
								</p>

								<p
									className={styles['header__details-right--user-profile']}

									// onClick={myProfileHandler}
								>
									<a
										// onClick={() => {
										// 	const url = `${
										// 		process.env.REACT_APP_OUTSETA_PROFILE_EMBED
										// 	}?access_token=${localStorage.getItem(
										// 		'outsetaAccessToken'
										// 	)}`;
										// 	window.open(url, '_blank'); // Opens in a new tab
										// }}
										data-o-profile="1"
										data-mode="popup"
										data-tab="profile"
										style={{
											color: 'black',
											fontSize: '12px',
											fontWeight: 300,
										}}
									>
										My Profile
									</a>
								</p>
							</div>
						</div>
						<div className={styles['header__details-right--user-logout']}>
							<div
								className={isLogoutLoading ? 'pointer-event-none' : ''}
								onClick={logoutHandler}
							>
								<button
									type="button"
									ref={hiddenButtonRef}
									style={{ display: 'none' }}
									data-o-logout-link="1"
								></button>

								<Image
									src="/assets/images/logout.png"
									preview={false}
									alt="group"
								/>
							</div>
						</div>
					</Col>
				</Row>
			</header>

			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={drawerInfo.drawerTitle}
					position="bottom"
					width="full"
				>
					<SettingsLayout
						filteredMenuItems={filteredMenuItems}
						onSideBarChange={sideBarChangeHandler}
						selectedSidebar={selectedSidebar}
					>
						<SettingsBody
							selectedSidebar={selectedSidebar}
							setSelectedSidebar={setSelectedSidebar}
							closeDrawerByAnimation={closeDrawerByAnimation}
							openDrawerHandler={openDrawerHandler}
						/>
					</SettingsLayout>
				</SideDrawerWrapper>
			)}

			{isProfileModalOpen && (
				<UserProfileModal
					isProfileModalOpen={isProfileModalOpen}
					handleCancel={profileCancel}
				/>
			)}
		</>
	);
};

export default Header;
