import React from 'react';
import { Modal, Button, Typography, Space } from 'antd';

const { Text, Link } = Typography;

interface ConfirmationModalProps {
	visible: boolean;
	onCancel: () => void;
	onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
	visible,
	onCancel,
	onConfirm,
}) => {
	return (
		<Modal
			open={visible}
			onCancel={onCancel}
			footer={null}
			width={550}
			zIndex={9999}
		>
			<Space
				direction="vertical"
				style={{ width: '100%', padding: '10px' }}
			>
				<Typography.Title level={3}>Add New Company</Typography.Title>
				<Text strong>
					Adding a new company will increase the subscription quantity for your
					account billing.
				</Text>
				<Text>
					Visit our{' '}
					<Link
						onClick={() =>
							window.open(
								'https://costallocationpro.outseta.com/support/kb/articles/79O3M49E/how-does-our-pricing-work-',
								'_blank'
							)
						}
						style={{
							fontWeight: 'bold',
							textDecoration: 'underline',
							color: '#344735',
						}}
					>
						knowledge base
					</Link>
					for subscription details.
				</Text>

				<Space
					style={{
						width: '100%',
						justifyContent: 'flex-end',
						marginTop: '20px',
					}}
				>
					<Button
						onClick={onCancel}
						size="large"
					>
						Cancel
					</Button>
					<Button
						type="primary"
						onClick={onConfirm}
						style={{
							backgroundColor: '#344735',
							color: '#fff',
						}}
						size="large"
					>
						Continue
					</Button>
				</Space>
			</Space>
		</Modal>
	);
};

export default ConfirmationModal;
