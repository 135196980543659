import { Header, Sidebar } from 'components/Global';
import { FORMDATA } from 'constants/Data';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Outlet,
	useLocation,
	useNavigate,
	useSearchParams,
	// useSearchParams,
} from 'react-router-dom';
import { checkPermission } from 'utils/utils';
import styles from './index.module.scss';
import { LayoutExpandInterFace } from 'pages/CostAllocation/types';
import { fetchClosingDateAction } from 'redux/action/closingDateAction';
import { AppDispatch } from 'redux/store';
import useIdleRedirect from 'utils/logoutTimer';
import axios from 'axios';
import { loginAction } from 'redux/slice/loginSlice';
import { fetchProfileAction } from 'redux/action/profileAction';
import { getCompanies } from 'redux/slice/companySlice';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Creating the global layout for along all the pages
const GlobalLayout = () => {
	// inits
	const { pageMenuItems } = FORMDATA;
	const { isLoading, data: userData } = useSelector(
		(state: any) => state.userProfile
	);
	const { pathname } = useLocation();
	const [finalMenuItems, setMenuItems] = useState<any>([]);

	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const dispatch = useDispatch<AppDispatch>();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	const { isExpanded } = useSelector(
		(state: LayoutExpandInterFace) => state?.layoutExpand
	);

	const navigate = useNavigate();

	// const [queryParams] = useSearchParams();

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const isDashboardPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Dashboard',
		permission: ['view'],
	});

	const isEmployeePermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Employee Cost',
		permission: ['view'],
	});

	const isTimeLogPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Logs',
		permission: ['view'],
	});

	const isTimeSheetPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Sheets',
		permission: ['view'],
	});

	const isCostAllocationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Cost Allocations',
			permission: ['view'],
		}
	);

	const isJournalEntriesPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Journals Entries',
			permission: ['view'],
		}
	);

	const isTimeSummaryPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Summary',
		permission: ['view'],
	});

	const isPayrollSummaryPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Payroll Summary',
			permission: ['view'],
		}
	);

	const isCustomerSummaryPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Customer Overview',
			permission: ['view'],
		}
	);

	// const isPayPeriodsPermission = checkPermission(selectedCompanyPermission, {
	// 	permissionName: 'Pay Period',
	// 	permission: ['view'],
	// });

	useEffect(() => {
		let menuItems = pageMenuItems;
		if (!isDashboardPermission) {
			menuItems = menuItems?.filter((item) => item?.key !== 'dashboard');
		}

		// if (!isEmployeePermission && !isPayPeriodsPermission) {
		// 	menuItems = menuItems?.filter((item) => item?.key !== 'employee-costs');
		// }

		if (!isTimeLogPermission && !isTimeSheetPermission) {
			menuItems = menuItems?.filter((item) => item?.key !== 'time-activity');
		}

		if (!isCostAllocationPermission && !isJournalEntriesPermission) {
			menuItems = menuItems?.filter((item) => item?.key !== 'cost-allocations');
		}

		if (!isJournalEntriesPermission) {
			menuItems = menuItems?.filter((item) => item?.key !== 'journal-entries');
		}

		if (
			!isTimeSummaryPermission &&
			!isPayrollSummaryPermission &&
			!isCustomerSummaryPermission
		) {
			menuItems = menuItems?.filter((item) => item?.key !== 'reports');
		}

		setMenuItems(menuItems);
	}, [
		isEmployeePermission,
		isTimeLogPermission,
		isDashboardPermission,
		isCostAllocationPermission,
		isTimeSheetPermission,
		isJournalEntriesPermission,
		pageMenuItems,
	]);

	const [selectedSidebar, setSelectedSidebar] = useState<string>('dashboard');

	const handleSidebar = (sidebarProps: any) => {
		if (sidebarProps?.key === 'employee-costs') {
			setSelectedSidebar('employee-costs');
			navigate('/employee-costs');
		} else if (sidebarProps?.key === 'time-activity') {
			setSelectedSidebar('time-activity');
			navigate('/time-activity');
		} else if (sidebarProps?.key === 'cost-allocations') {
			setSelectedSidebar('cost-allocations');
			navigate('/cost-allocations');
		} else if (sidebarProps?.key === 'journal-entries') {
			setSelectedSidebar('journal-entries');
			navigate('/journal-entries');
		} else if (sidebarProps?.key === 'reports') {
			setSelectedSidebar('reports');
			navigate('/reports');
		} else {
			setSelectedSidebar('dashboard');
			navigate(`/`);
		}
	};
	const initialFunctionCall = () => {
		if (pathname === '/employee-costs') {
			setSelectedSidebar('employee-costs');
		} else if (pathname === '/time-activity') {
			setSelectedSidebar('time-activity');
		} else if (pathname === '/cost-allocations') {
			setSelectedSidebar('cost-allocations');
		} else if (pathname === '/journal-entries') {
			setSelectedSidebar('journal-entries');
		} else if (pathname === '/reports') {
			setSelectedSidebar('reports');
		} else {
			setSelectedSidebar('dashboard');
		}
	};

	useEffect(() => {
		initialFunctionCall();
	}, [pathname]);

	const [searchParams, setSearchParams] = useSearchParams();
	const [profileData, setProfileData] = useState(null);

	useEffect(() => {
		// Remove access_token from the URL after extracting it for security purposes
		if (searchParams.get('access_token')) {
			const accessToken = searchParams.get('access_token');
			if (accessToken) localStorage.setItem('outsetaAccessToken', accessToken); // Store in localStorage
			setSearchParams({}); // Clear the search params (access_token)
		}
	}, []);

	// useEffect(() => {

	// }, [profileData]);
	const [isOutsetaLoading, setIsOutsetaLoading] = useState(false);

	useEffect(() => {
		const fetchProfileData = async () => {
			const outsetaToken = localStorage.getItem('outsetaAccessToken');
			const token = localStorage.getItem('accessToken');

			if (!outsetaToken) {
				navigate('/login');
				return;
			}

			if (!outsetaToken || !token) {
				try {
					localStorage.setItem('outsetaLoginStart', 'true'); // Store in localStorage
					setIsOutsetaLoading(true);

					const response = await axios
						.get('https://costallocationpro.outseta.com/api/v1/profile', {
							headers: {
								Authorization: `Bearer ${outsetaToken}`,
								'Content-Type': 'application/json',
							},
						})
						.catch((error) => {
							console.log('error', error);
						});

					if (response && response.data) {
						console.log('profileData', response.data);
						setProfileData(response.data); // Store profile data in state
						console.log('profileData State', profileData);

						dispatch(loginAction({ email: response.data.Email }) as any)
							.unwrap()
							.then(() => {
								dispatch(fetchProfileAction() as any)
									.unwrap()
									.then((res: any) => {
										setIsOutsetaLoading(false);
										dispatch(getCompanies(res));
										if (res.data.isSuperAdmin) {
											navigate('/admin');
										} else {
											navigate('/');
											window.location.reload();
										}
									});
							})
							.catch(() => {
								setIsOutsetaLoading(false);

								navigate('/login');
							});
					} else {
						console.error('No profile data found');
					}
				} catch (error) {
					console.error('Error fetching profile data:', error);
					setIsLoggedIn(false);
				}
			} else {
				setIsLoggedIn(true);
				setIsOutsetaLoading(false);
			}
		};
		console.log('isOutsetaLoading', isOutsetaLoading);
		fetchProfileData(); // Call the async function
	}, []);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state?.companies
	);

	useEffect(() => {
		if (userData && !userData.isSuperAdmin) {
			dispatch(fetchClosingDateAction({}));
		}
	}, [selectedCompanyDetails, userData]);

	useEffect(() => {
		if (userData && userData.isSuperAdmin) {
			navigate('/admin');
		}
	}, [userData]);
	useIdleRedirect();

	// JSX
	return !isLoading ? (
		isLoggedIn ? (
			<div
				className={styles['global-layout']}
				style={{ overflowY: 'hidden' }}
			>
				<div className={styles['global-layout__wrapper']}>
					<div className={styles['global-layout__header']}>
						<Header />
					</div>
					<div className={styles['global-layout__body']}>
						{!userData?.isSuperAdmin ? (
							<div
								className={
									styles[
										`global-layout__body--sidebar-${
											isExpanded ? 'hide' : 'show'
										}`
									]
								}
							>
								<Sidebar
									items={finalMenuItems}
									// items={pageMenuItems}
									isGetSupportButton={false}
									handleSidebar={handleSidebar}
									selectedSidebar={selectedSidebar}
								/>
							</div>
						) : null}

						<div
							className={
								styles[
									`global-layout__body--body-${
										isExpanded || userData?.isSuperAdmin
											? 'widthFull'
											: 'widthFixed'
									}`
								]
							}
						>
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		) : (
			<div className={styles['spin-container']}>
				<Spin
					indicator={
						<LoadingOutlined
							style={{ fontSize: 48, color: '#344735' }}
							spin
						/>
					}
				/>
				<p className={styles['spin-message']}>
					Just a moment! We&apos;re fetching your data from the server.
				</p>
			</div>
		)
	) : (
		<div className={styles['spin-container']}>
			<Spin
				indicator={
					<LoadingOutlined
						style={{ fontSize: 48, color: '#344735' }}
						spin
					/>
				}
			/>
			<p className={styles['spin-message']}>
				Just a moment! We&apos;re fetching your data from the server.
			</p>
		</div>
	);
};

export default GlobalLayout;
