/* eslint-disable @typescript-eslint/no-explicit-any */
import NewConnectionModel from 'components/settings/Integrations/NewConnectionModel';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getApi } from 'redux/apis';
import ConfirmationModal from '../BuySubscriptionModal';
// import { toastText } from 'utils/utils';
// import { message } from 'antd';

export const BuySubscriptionButton = ({
	isForDropDown,
	closeDropdown,
}: any) => {
	const { companies } = useSelector((state: any) => state?.userProfile?.data);

	const [openNewConnectionModel, setOpenNewConnectionModel] = useState(false);

	const [subscriptionsData, setSubscriptionsData] = useState<any>(null);
	// const [adminCompanyCounts, setAdminCompanyCounts] = useState(0);

	// const [isLoading, setIsLoading] = useState(false);

	// const handleSubscriptionConfirm = async () => {
	// 	setModalVisible(false);

	// 	// Prepare the API request payload
	// 	const payload = {
	// 		outSetaToken: localStorage.getItem('outsetaAccessToken'),
	// 		// BillingRenewalTerm: isYearly,
	// 		// Quantity: companyCount,
	// 	};

	// 	try {
	// 		const updatedSubscription = await postApi(
	// 			'/outseta/add-subscription',
	// 			payload
	// 		);
	// 		console.log('Subscription Response:', updatedSubscription);
	// 		message.success('Subscription added successfully!');
	// 	} catch (error) {
	// 		console.error('Subscription Error:', error);
	// 		message.error('Failed to add subscription.');
	// 	}
	// };

	useEffect(() => {
		if (companies && companies.length) {
			const adminCompany = companies.filter((e: any) => e.role.isCompanyAdmin);
			console.log('companies', subscriptionsData, adminCompany);
			// setAdminCompanyCounts(adminCompany.length);

			// const selectedCompanyId = localStorage.getItem('companyId');

			// if (selectedCompanyId) {
			// 	const selectedCompanyData = companies.find(
			// 		(e: any) => e.companyId === selectedCompanyId
			// 	);

			// 	// setRoleData(selectedCompanyData?.role);

			// 	// if (selectedCompanyData &&
			// 	// 	selectedCompanyData.company &&
			// 	// 	selectedCompanyData.company.Subscription &&
			// 	// 	selectedCompanyData.company.Subscription.length) {
			// 	// 	setSubscriptionData(selectedCompanyData.company.Subscription[0])
			// 	// 	const _subscriptionData = selectedCompanyData.company.Subscription[0];

			// 	// 	let _addonQuantity = 0;

			// 	// 	if (_subscriptionData && _subscriptionData.addons && _subscriptionData.addons.length) {

			// 	// 		_subscriptionData.addons.forEach((addon: any) => {
			// 	// 			_addonQuantity = _addonQuantity + addon.quantity
			// 	// 		});
			// 	// 	}

			// 	// 	setAddonQuantity(_addonQuantity);

			// 	// }
			// }
		}
		getSubscriptions().catch(console.error);
	}, [companies]);

	const getSubscriptions = async () => {
		const res = await getApi('/subscription/logged-in', {
			companyId: localStorage.getItem('companyId'),
		});

		if (res.data.data && res.data.data.length) {
			const data = res.data.data.filter(
				(e: any) =>
					e.status === 'live' ||
					e.status === 'trial' ||
					e.status === 'Trialing' ||
					e.status === 'Active'
			);
			setSubscriptionsData(data);
		}
	};

	// const getHostedPaymentPage = async () => {
	// 	try {
	// 		setIsLoading(true);
	// 		handleOpenModal();

	// 		// const res = await getApi('/zoho/hosted-page', {
	// 		// 	companyId:
	// 		// 		subscriptionsData && subscriptionsData.length
	// 		// 			? subscriptionsData[0].companyId
	// 		// 			: '',
	// 		// });

	// 		// if (res.data && res.data.data && res.data.data.hostedUrl) {
	// 		// 	handleOpenModal();
	// 		// }
	// 	} catch (error: any) {
	// 		toastText(error.message, 'error');
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	const [isModalVisible, setModalVisible] = useState(false);

	// const handleOpenModal = () => setModalVisible(true);
	const handleCloseModal = () => setModalVisible(false);

	const handleSubscriptionConfirm = () => {
		setModalVisible(false);
		setOpenNewConnectionModel(true);
	};

	const handleOpenModal = () => {
		if (closeDropdown) closeDropdown(); // Call isCloseDropDown before opening modal
		setModalVisible(true);
	};

	return (
		<>
			{/* {subscriptionsData?.length > adminCompanyCounts ? (
				<button
					className={!isForDropDown ? 'btn-black' : 'btn-black-small'}
					onClick={() => setOpenNewConnectionModel(true)}
				>
					Add New Company
				</button>
			) : (
				<button
					className={!isForDropDown ? 'btn-black' : 'btn-black-small'}
					onClick={() => getHostedPaymentPage()}
				>
					{isLoading ? (
						<img
							src="assets/gifs/loading-black.gif"
							height={30}
						/>
					) : (
						'Buy New Subscription To Add Company'
					)}
				</button>
			)} */}
			<button
				className={!isForDropDown ? 'btn-black' : 'btn-black-small'}
				onClick={() => handleOpenModal()}
			>
				Add New Company
			</button>

			<NewConnectionModel
				open={openNewConnectionModel}
				onCancel={() => setOpenNewConnectionModel(false)}
			/>

			<ConfirmationModal
				visible={isModalVisible}
				onCancel={handleCloseModal}
				onConfirm={handleSubscriptionConfirm}
			/>
		</>
	);
};
